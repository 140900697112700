const countryList = [
  {
    countryCode: "NO",
    countryName: "Norge",
    priceKey: "NOK",
    currencyText: "NOK",
    currencySymbol: ",-",
  },
  {
    countryCode: "DE",
    countryName: "Germany",
    priceKey: "EUR",
    currencyText: "EUR",
    currencySymbol: "€",
  },
  // { countryCode: "US", countryName: "USA" }
  {
    countryCode: "NL",
    countryName: "Netherlands",
    priceKey: "EUR",
    currencyText: "EUR",
    currencySymbol: "€",
  },
  {
    countryCode: "SE",
    countryName: "Sweden",
    priceKey: "SEK",
    currencyText: "SEK",
    currencySymbol: "kr",
  },
  {
    countryCode: "DK",
    countryName: "Denmark",
    priceKey: "DKK",
    currencyText: "DKK",
    currencySymbol: "kr",
  },
  {
    countryCode: "FI",
    countryName: "Finland",
    priceKey: "EUR_FI",
    currencyText: "EUR",
    currencySymbol: "€",
  },
  {
    countryCode: "EE",
    countryName: "Estonia",
    priceKey: "EUR",
    currencyText: "EUR",
    currencySymbol: "€",
  },
  {
    countryCode: "FR",
    countryName: "France",
    priceKey: "EUR",
    currencyText: "EUR",
    currencySymbol: "€",
  },
  {
    countryCode: "IS",
    countryName: "Iceland",
    priceKey: "EUR",
    currencyText: "EUR",
    currencySymbol: "€",
  },
  {
    countryCode: "LU",
    countryName: "Luxembourg",
    priceKey: "EUR",
    currencyText: "EUR",
    currencySymbol: "€",
  },
  {
    countryCode: "ES",
    countryName: "Spain",
    priceKey: "EUR",
    currencyText: "EUR",
    currencySymbol: "€",
  },
  {
    countryCode: "GR",
    countryName: "Greece",
    priceKey: "EUR",
    currencyText: "EUR",
    currencySymbol: "€",
  },
  {
    countryCode: "CH",
    countryName: "Switzerland",
    priceKey: "EUR",
    currencyText: "EUR",
    currencySymbol: "€",
  },
];

export default countryList;
