import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocale } from "@lilbit/shared";
import { CountryContext, CartContext } from "../../context";
import axios from "axios";
import Image from "next/image";

const Klarna = ({ product, coupon, productTaxRate, input, getTotalPrice }) => {
  const appLanguage = useLocale().appLanguage;
  const countryContext = useContext(CountryContext);
  const { nrOfMainProducts, mainProductPrices } = useContext(CartContext);
  const [productID, setProductID] = useState(product?.id);
  const [loading, setLoading] = useState(false);
  const [klarnaHtmlSnippet, setKlarnaHtmlSnippet] = useState("");

  useEffect(() => {
    setProductID(product?.id);
    if (productID) createOrder();
  }, [product, CartContext, coupon]);

  /* Track event when user starts checkout */
  useEffect(() => {
    /* Converge Added Payment Info event */
    if (typeof window.cvg === "function") {
      //@ts-ignore to ignore the error that cvg is not defined. This should not be in window.load as it loads after the user clicks the klarna button
      cvg({
        method: "track",
        eventName: "Added Payment Info",
        properties: {
          total_price: getTotalPrice(),
          currency: countryContext.country.currency,
          items: [
            {
              product_id: product.id, // Woocommerce product ID
              sku: product.sku,
              name: product.name,
              price: mainProductPrices?.PRICE[countryContext.country.priceKey],
              currency: countryContext.country.currency,
              quantity: nrOfMainProducts,
            },
          ],
        },
      });
    }
    // TikTokPixelAddPaymentKlarna
    if (
      typeof window !== "undefined" &&
      window.ttq &&
      typeof window.ttq.track === "function"
    ) {
      const formData = {
        value: getTotalPrice(),
        currency: countryContext.country.currency,
        description: "klarna",
        contents: [
          {
            content_id: product.id,
            content_name: product.name,
          },
        ],
      };
      window.ttq.track("AddPaymentInfo", formData);
    }
    if (
      typeof window !== "undefined" &&
      window.snaptr &&
      typeof window.snaptr === "function"
    ) {
      const formData = {
        price: getTotalPrice(),
        currency: countryContext.country.currency,
        item_ids: product.id,
        description: "klarna",
        number_item: nrOfMainProducts,
      };
      window.snaptr("track", "ADD_BILLING", formData);
    }
  }, [product, mainProductPrices, nrOfMainProducts]);

  //Scroll
  const creditRef = useRef(null);
  const executeScroll = () =>
    creditRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

  const createOrder = async () => {
    setLoading(true);

    const klarna_data = {
      purchase_country: input.country,
      purchase_currency: countryContext.country.currency,
      locale: appLanguage,
      product_price:
        countryContext.country.currency === "NOK"
          ? product?.nok_price
          : countryContext.country.currency === "USD"
          ? product?.usd_price
          : countryContext.country.currency === "EUR"
          ? product?.euro_price
          : "",
      tax_rate: productTaxRate,
      product_name: product?.name,
    };
    await axios
      .post("/api/payment/klarna/checkout_order", {
        subscribe_to_newsletter: input.subscribeNews,
        subscribe_to_newsletter_email: input.email,
        order: {
          country: input.country,
        },
        products: [
          {
            id: productID,
            quantity: nrOfMainProducts,
          },
        ],
        coupon: coupon,
        payment_method: "klarna",
        payment_method_title: "Klarna",
        shipping_method_id:
          countryContext.country.countryCode === "NO" ? "Post_nord" : "DHL",
        //TODO Stemmer denne sjekken?
        shipping_method_title: countryContext.country.countryCode
          ? "Post Nord"
          : "DHL",
        klarna_data: klarna_data,
        cvg_uid: document?.cookie?.match(/__cvg_uid=([^;]+)/)?.[1] || "",
      })
      .then(async (res) => {
        setKlarnaHtmlSnippet(res.data.html_snippet);
        setLoading(false);
      });
    setLoading(false);
  };
  useEffect(() => {
    if (
      typeof window !== undefined &&
      klarnaHtmlSnippet !== "" &&
      !loading &&
      document.getElementById("KCO")
    ) {
      var checkoutContainer = document.getElementById("my-checkout-container");
      // prettier-ignore
      // @ts-ignore
      checkoutContainer.innerHTML = document.getElementById("KCO").value.replace(/\\"/g, '"')
      .replace(/\\n/g, "");
      var scriptsTags = checkoutContainer.getElementsByTagName("script");
      for (var i = 0; i < scriptsTags.length; i++) {
        var parentNode = scriptsTags[i].parentNode;
        var newScriptTag = document.createElement("script");
        newScriptTag.type = "text/javascript";
        newScriptTag.text = scriptsTags[i].text;
        parentNode.removeChild(scriptsTags[i]);
        parentNode.appendChild(newScriptTag);
      }
    }
  }, [klarnaHtmlSnippet, loading]);

  // Scroll to start of klarna pay component when choosing credit pay
  useEffect(() => {
    if (window.innerWidth < 1024) executeScroll();
  }, []);

  return (
    <div className="w-full mt-4" ref={creditRef}>
      {!loading ? (
        <div className="mt-4 h-full rounded-t-3xl overflow-hidden ">
          <textarea
            style={{ display: "none" }}
            id="KCO"
            defaultValue={klarnaHtmlSnippet}
          />
          <div id="my-checkout-container"></div>
        </div>
      ) : (
        <div className="flex h-[400px] justify-center items-center">
          <Image
            src={`/carticons/loading_icon.png`}
            className="animate-spin"
            height={100}
            width={100}
            alt="loading"
          />
        </div>
      )}
    </div>
  );
};

export default Klarna;
