import React, { useContext, useEffect } from "react";
import { CartContext, CheckoutForm, CountryContext } from "@lilbit/shared";
import { upperFirst } from "lodash";
import { Props } from "../checkout/form";

const Checkout = (props: Props) => {
  const { mainProduct, siteName } = props;
  const { addProduct, nrOfMainProducts, mainProductPrices } =
    useContext(CartContext);
  const { country } = useContext(CountryContext);
  useEffect(() => {
    addProduct(mainProduct);
  }, []);

  useEffect(() => {
    if (window !== undefined) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: [
            {
              item_id: mainProduct.id,
              item_name: mainProduct.name,
              affiliation: upperFirst(siteName),
              currency: country.currency,
              price: mainProductPrices?.PRICE[country.priceKey],
              quantity: nrOfMainProducts,
            },
          ],
        },
      });
    }
  }, []);

  return (
    <div className="w-full h-full">
      <CheckoutForm {...props} />
    </div>
  );
};
export default Checkout;
