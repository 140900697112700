import React from "react";
import Vippsbutton from "./Vippsbutton";
import KlarnaExpress from "./KlarnaExpress";
import StripeExpressCheckout from "./checkout/stripeExpressCheckout";
import AddToCartWithCardLogos from "./Button/addToCartWithCardLogos";
import { GlobalConfig, Product } from "../lib";

interface Props {
  getTotalPrice: Function;
  product: any;
  coupon?: any;
  productTaxRate: any;
  country: any;
  nrMainProducts: number;
  klarna?: boolean;
  vipps?: boolean;
  config?: GlobalConfig;
  mainProduct?: Product;
}

const FastCheckout = (props: Props) => {
  const {
    getTotalPrice,
    product,
    coupon,
    productTaxRate,
    country,
    nrMainProducts,
    klarna = true,
    vipps = true,
    config,
    mainProduct,
  } = props;
  const klarnaInput = {
    country: country.countryCode,
    createAccount: false,
    paymentMethod: "klarna",
    acceptTerms: true,
    subscribeNews: false,
    errors: null,
  };
  const productID = product?.id;
  const couponCode = coupon?.code;

  return (
    <div className="grid grid-cols-1 z-30">
      {country.countryCode !== "NO" && (
        <div className="w-full">
          <StripeExpressCheckout
            productID={productID}
            coupon={coupon}
            input={undefined}
            getTotalPrice={getTotalPrice}
            mainProduct={product}
            nrMainProducts={nrMainProducts}
          />
        </div>
      )}
      <div className={`grid gap-2 grid-cols-1 md:grid-cols-2`}>
        {vipps && country.countryCode === "NO" && (
          <div className="w-full">
            <Vippsbutton
              productID={productID}
              coupon={couponCode}
              lgScreenWidth={""}
              smScreenWidth={""}
              input={{
                acceptTerms: true,
                subscribeNews: false,
              }}
              getTotalPrice={getTotalPrice}
              mainProduct={product}
            />
          </div>
        )}
        {config && mainProduct && country.countryCode !== "NO" && (
          <div className="w-full">
            <AddToCartWithCardLogos config={config} mainProduct={mainProduct} />
          </div>
        )}
        {klarna && (
          <div className="w-full">
            <KlarnaExpress
              input={klarnaInput}
              getTotalPrice={getTotalPrice}
              coupon={coupon}
              productTaxRate={productTaxRate}
              productID={productID}
              lgScreenWidth={""}
              smScreenWidth={""}
              mainProduct={product}
              country={country}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default FastCheckout;
